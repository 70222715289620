<template>
    <DnModal
        class="modal"
        @close="modalStore.deactivateAllModals"
    >
        <template #close-button>
            <div class="modal__close">
                <BaseButton
                    class="base-button--transparent-dark base-button--icon-only"
                    icon="close"
                    aria-label="close"
                    @click="modalStore.deactivateAllModals"
                />
            </div>
        </template>

        <template #default>
            <div class="modal__header">
                <h2 class="modal__title"><slot name="title" /></h2>
            </div>

            <div class="modal__body">
                <slot name="default" />
            </div>
        </template>

        <template 
            v-if="!!$slots.dialog"
            #dialog
        >
            <slot name="dialog" />
        </template>
    </DnModal>
</template>

<script setup>
import DnModal from '@digitalnatives/modal';
import { useModalStore } from '~/store/modal';

const modalStore = useModalStore();
</script>

<style src="./BaseModal.less" lang="less"></style>
